<template>
  <q-table :columns="COLUMNS" @request="onRequest" :loading="loading" :rows="rows" selection="multiple" v-model:selected="selectedModel" v-model:pagination="pagination" ref="tableRef" :filter="filter" flat bordered title="Puntos de interés" row-key="id_punto">
    <template v-slot:top-right>
      <q-input dense debounce="300" v-model="filter" placeholder="Buscar punto">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
  </q-table>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";

const COLUMNS = [
  {name: 'id_punto', field: 'id_punto', label: 'ID', align: 'left'},
  {name: 'nombre', field: 'nombre', label: 'Nombre', sorteable: false, align: 'left'},
  {name: 'descripcion', field: 'descripcion', label: 'Descripción', sorteable: false, align: 'left'}
];

export default defineComponent({
  name: "PuntosTable",
  props: ['selected'],
  emits: ['update:selected'],
  setup(props, {emit}) {
    const { make } = useApi();

    const selectedModel = computed({
      get() {
        return props.selected;
      },
      set(value) {
        emit('update:selected', value)
      }
    });

    const state = reactive({
      loading: false,
      tableRef: null,
      filter: null,
      rows: [],
      pagination: {
        page: 1,
        rowsPerPage: 20,
        rowsNumber: 0,
        sortBy: null,
        descending: 'asc'
      }
    });

    async function onRequest(props){
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter;
      state.loading = true;
      let {data} = await make('puntos/get-all', {
        config: {
          current_page: page,
          per_page: rowsPerPage,
          order: descending
        },
        filters: [
          {key: 'nombre', value: filter ? filter : ''}
        ]
      });
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.pagination = {
            page,
            rowsPerPage,
            sortBy,
            descending,
            rowsNumber: data.total
          };
        }
      }
      state.loading = false;
    }

    onMounted(() => {
      state.tableRef.requestServerInteraction();
    });

    return {
      COLUMNS,
      ...toRefs(state),
      selectedModel,
      onRequest
    }
  }
})
</script>

<style scoped>

</style>
